import { Injectable } from "@angular/core";
import {HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { GlobalService } from "@app/shared/services/global/global.service";
import { HttpClientService } from "@app/core/services/http-client/http-client.service";
import { ItemsListModel } from "../modal/employeeItemsList";
@Injectable({
  providedIn: "root",
})
export class ExitServiceService {
  selectedItems: ItemsListModel;

  baseIp = environment.baseIp;
  apiPrefix = environment.apiPrefix;
  accessToken = this.globalService.getAccessTokenFromCookie();
  companyId = this.globalService.getCompanyIdFromStorage();
  private apiUrl = environment.baseIp + environment.apiPrefix;

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
    private httpClientService: HttpClientService,
  ) {}

  getEmployeeItemsLogs(body){
    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}employee-item/search`,
      body
    );
  }
  
  getUserList() {
    const params = new HttpParams().set("company_id", this.companyId);
    return this.httpClientService.get(
      `${this.baseIp}${this.apiPrefix}userstaff`,
      null,
      params
    );
  }

  deleteEmployeeItems(body): Observable<any> {
    return this.httpClientService.post(`${this.apiUrl}employee-item/remove`, body);
  }

  addEmployeeItems(body): Observable<any> {
    return this.httpClientService.post
    (`${this.apiUrl}employee-item/add`, body);
  }

  updateEmployeeItems(body): Observable<any> {
    return this.httpClientService.post(
      `${this.apiUrl}employee-item/edit`,
       body);
  }

  clearEmployeeItems(body): Observable<any> {
    return this.httpClientService.post(`${this.apiUrl}employee-item/clearance`, body);
  }

  getSelectedItems() {
    return this.selectedItems;
  }
  
  setSelectedItems(dataitem): void {
    this.selectedItems = dataitem;
  }
}
