export const MaskConst = {
  PHONE_NUMBER: {
    guide: false,
    modelClean: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
  PHONE: {
    guide: false,
    modelClean: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
  ZIP: {
    guide: false,
    modelClean: true,
    showMask: true,
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
  },
  FAX_NUMBER: {
    guide: false,
    modelClean: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
  SSN: {
    guide: false,
    modelClean: true,
    showMask: true,
    mask: [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
  },
  POSTAL_CODE: {
    guide: false,
    modelClean: true,
    showMask: true,
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
  },

  PIN: {
    guide: false,
    modelClean: true,
    showMask: true,
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  },
};
