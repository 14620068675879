
import { Injectable } from "@angular/core";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { HttpClientService } from "@app/core/services/http-client/http-client.service";
import { ClearanceListModel } from "../model/createclearance.interface";
import { GlobalService } from "@app/shared/services/global/global.service";

@Injectable({
  providedIn: "root",
})
export class CreateClearanceService {
 
  baseIp = environment.baseIp;
  apiPrefix = environment.apiPrefix;
  selectedItems: ClearanceListModel;
  accessToken = this.globalService.getAccessTokenFromCookie();
  companyId = this.globalService.getCompanyIdFromStorage();
  constructor(
    private httpClientService: HttpClientService,
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) {}

  getDepartmentList(paramsData) {
    const params = new HttpParams()
      .set("company_id", this.companyId)
      .set("limit", paramsData.limit)
      .set("page", paramsData.page)
      .set("sortno", paramsData.sortno)
      .set("sortnane", paramsData.sortnane)
      .set("search_key", paramsData.search_key)
      .set("search_value", paramsData.search_value);

    return this.httpClientService.get(
      `${this.baseIp}${this.apiPrefix}departments`,
      null,
      params
    );
  }

  searchItems(getBody:any){
    return this.httpClientService.post(
       `${this.baseIp}${this.apiPrefix}clearances/search`, 
       getBody
       );
  }

  addDepartment(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    console.log(" this is api body " + JSON.stringify(body));
    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}clearances/add`,
      body
    );
  }

  // edit method

  editDepartment(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}clearances/edit`,
      body,
      options
    );
  }

  // delete method

  deleteDepartment(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${this.baseIp}${this.apiPrefix}clearances/remove`,
      id,
      options
    );
  }

  setselectedItems(department): void {
    this.selectedItems = department;
  }

  getselectedItems() {
    return this.selectedItems;
  }


getUserList() {
  const params = new HttpParams().set("company_id", this.companyId);
  return this.httpClientService.get(
    `${this.baseIp}${this.apiPrefix}userstaff`,
    null,
    params
  );
}

searchUserClearance(getBody:any){
  return this.httpClientService.post(
     `${this.baseIp}${this.apiPrefix}user-clearances/search`, 
     getBody
     );
}

}
